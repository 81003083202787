import React, {useState} from 'react';
import Layout from '../components/Layout';
import Navigation from '../components/Navigation';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby'
import parse from 'html-react-parser'

const ShowMore = (content) =>{
    const [showMore, setShowMore] = useState(false);
    const style = showMore? {}:{height: "100px", overflow:"hidden"} 
    
    return <div>
        <div style={style}>
            {content}
        </div>
        <button className="btn" style={{float:"right"}} onClick={() => setShowMore(!showMore)}>
            {showMore ? "Show less" : "Show more"}
        </button>
    </div>
}

const Episode = ep =><div className="episode">
    <a href={ep.link} >
        <img className="episode-thumbnail" src={ep.itunes.image} alt="episode thumbnail"/>
    </a>
    <div >
        <a href={ep.link}><h2 className="episode-title">{ep.title}</h2></a>
        <p className="episode-content">{ShowMore(parse(ep.content))}</p>
    </div>
</div>


const PodcastPage = ({data}) =>{
    const podcast = data.allAnchorPodcast.edges[0].node;
    const episodes = podcast.items;
    const ep = episodes[0].item;

    return <Layout >
        <Helmet>
            <style>
                {'html{background: #EBEBEC !important}'}
                
            </style>
        </Helmet>
        <Navigation color="light" />
        <div style={{background:"#EBEBEC"}}>
        <div className="podcast-header">
            <div className="podcast-meta">
                <div className="row">
                    <div className="col-4 col-12-medium">
                        <img src={podcast.image.url} width="100%" style={{borderRadius:"1.5%"}} alt="sacred garden mindfulness podcast"/>
                    </div>
                    <div className="col-8 col-12-medium right">
                        <h1>{podcast.title}</h1>
                        <p >By {podcast.creator}</p>
                        <p>{parse(podcast.description)}</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="play-list">
            {episodes.map(ep=>Episode(ep.item))}
        </div>
        </div>
        </Layout>
    
}


export const query = graphql`
query MyQuery {
    allAnchorPodcast {
      edges {
        node {
            title
          id
          feedUrl
          creator
          image {
            link
            title
            url
          }
          items {
            item {
              id
              title
              link
              content
              itunes {
                image
              }
            }
          }
          description
        }
      }
    }
  }
  
`


export default PodcastPage